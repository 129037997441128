import httpService from '../../../helpers/http.service'

export default {
    getByEnrollment(id, params) {
        return httpService.get(`enrollments/${id}/students`, {params})
    },
    get(id = null, params = {}) {
        return httpService.get(`enrollments${id ? '/' + id : '' }`, {params})
    },
    delete(id) {
      return   httpService.delete(`students/${id}`);
    },
    save(params, id = null) {
        if(id) {
            return httpService.put(`students/${id}`, params);

        }
        return httpService.post(`students`, params);
    },
    getStudentsByCountry(params = {}) {
        return httpService.get(`reports/students-country`, {params})
    }
}
